@import '../../../../../or_base/scss/theme-bootstrap';

.customkits-accordion {
  margin-top: 20px;
  border-top: 1px solid $color-darker-gray;
}

.customkits-accordion h3 {
  position: relative;
  padding: 0 0 15px;
  font-family: $font--futura-demi;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1;
}

.customkits-accordion h3:not(.active-header) {
  border-bottom: 1px solid $color-darker-gray;
}

.customkits-accordion h3.active-header + .product-quickshop__description {
  border-bottom: 1px solid $color-darker-gray;
}

.customkits-accordion h3::before,
.customkits-accordion h3::after {
  content: '';
  text-align: left;
  position: absolute;
  background: $color-darker-gray;
  cursor: pointer;
}

.customkits-accordion h3:not(.active-header)::before {
  width: 2px;
  height: 15px;
  right: 6px;
  top: 0;
}

.customkits-accordion h3::after {
  width: 15px;
  right: 0;
  height: 2px;
  top: 6px;
}

.product-quickshop__description {
  border-bottom: none;
}

.customkits-accordion .product-quickshop__description {
  text-align: left;
}

.product-quickshop__footer__add-to-bag {
  text-align: right;
}

.custom-kits-mpp-container .product-quickshop .quickshop-arrow {
  top: -1px;
}

.custom-kits-mpp-container .product-quickshop__sku-select {
  border-color: $color-darker-gray;
}

@media (min-width: 768px) {
  .custom-quickshop-image {
    float: left;
    padding-left: 30px;
  }
  .custom-quickshop-product {
    float: right;
    padding: 20px 40px !important;
  }
  .custom-kits-mpp-container .product-brief__rating {
    right: -10px;
    top: 0;
  }
  /* To override the base changes and write custom kits specific size and padding, I've used important rule as I had no choice */
  .pr-rating-stars div {
    height: 15px !important;
    width: 14px !important;
  }
  span.pr-snippet-review-count {
    padding: 0 !important;
  }
  .product-full__review-snippet .p-w-r {
    margin: 0 10px;
  }
  .product-quickshop__close__copy {
    display: none;
  }
  span.product-sku-price__value:first-child:not(:empty)::before {
    content: ' - (';
  }
  span.product-sku-price__value:first-child:not(:empty)::after {
    content: ')';
  }
}

.customkits-accordion h3 {
  text-align: left;
}

.product-quickshop-header {
  text-align: left;
  border-style: solid;
  border-width: 1px 0;
  padding: 10px 0;
  margin-bottom: 30px;
  border-color: $color-light-gray;
  position: relative;
}

.product-quickshop__sku-select {
  padding-top: 10px;
}

.product-sku-price {
  width: 50%;
  text-align: left;
  display: inline-flex;
  flex-direction: row;
}

span.product-sku-price__value {
  display: inline-block;
  padding-left: 10px;
  font-size: 20px;
}

span.product-sku-price__value:first-child {
  order: 1;
}

.pr-snippet-stars.pr-snippet-stars-png {
  background: transparent !important; /* need to override this */
}

.custom-kits-mpp-container .product-brief__rating {
  position: absolute;
}

@media (max-width: 767px) {
  html.no-scroll {
    overflow: hidden !important;
  }
  .customkits-accordion h3 {
    margin-bottom: 0;
  }
  .quickshop-wrapper.active {
    top: 56px;
    position: fixed;
    z-index: 999;
    visibility: visible;
    background-color: $color-white;
    height: 100%;
    overflow: scroll;
    padding-bottom: 190px;
    left: 0;
    width: 100%;
  }
  .active .quickshop-arrow {
    display: none;
  }
  .active .product-quickshop__footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 15px;
    left: 0;
    background: $color-light-gray;
  }
  .active .product-quickshop__footer__add-to-bag {
    margin-bottom: 0;
    text-align: center;
  }
  .active .product-quickshop__footer__add-to-bag .button {
    max-width: 100%;
    width: 100%;
  }
  .active .product-quickshop__main {
    top: 0px;
    background: $color-white;
    border-bottom: none;
  }
  .active .product-quickshop__quotes {
    display: block;
  }
  .active .product-quickshop-header {
    display: table;
    width: 100%;
    border-bottom: solid 1px $color-light-gray;
    border-top: 1px solid $color-light-gray;
  }
  .active .product-quickshop__close {
    position: fixed;
    top: 56px;
    background: $color-light-gray;
    font-family: $font--futura-demi;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 10px 10px;
    width: 100%;
    text-align: right;
  }
  .active .product-heading {
    padding-top: 40px;
    margin-bottom: 0px;
    border-bottom: none;
  }
  .active span.product-sku-price__value {
    padding-left: 0px;
  }
  .active span.product-sku-price__value:first-child {
    flex: 1;
    font-size: 16px !important;
    color: $color-dark-gray;
  }
  .active span.product-sku-price__value:nth-child(2) {
    width: 100%;
    font-size: 30px;
  }
  .active .product-sku-price {
    flex-wrap: wrap;
    width: 40%;
  }
  .product-brief__ctas--mobile {
    margin: 20px 0 0;
  }
  .product-brief__rating {
    bottom: -5px;
    right: -10px;
    width: 100%;
  }
  .product-quickshop__close__copy {
    display: inline;
    margin: 0px 10px;
    vertical-align: middle;
  }
  /* To override base and write custom kits specific , important has been used here */
  .pr-snippet-stars-reco-stars {
    float: right;
  }
}

.custom-kits-mpp-container .sku-list-sizes__list {
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
}

.custom-kits-mpp-container .sku-list-sizes__item {
  width: 48%;
  margin-top: 10px;
}

.custom-kits-mpp-container .sku-list-sizes__button {
  height: 40px;
  line-height: 40px;
  font-size: 22px;
  text-transform: uppercase;
  font-family: $font--futura-demi;
  background-color: $color-white;
  border: solid 1px $color-white;
  outline: solid 1px $color-dark-gray;
}

.custom-kits-mpp-container .sku-list-sizes__button.active {
  background-color: $color-light-green;
  box-shadow: inset 0 0 0 1px $color-white;
  color: $color-white;
  border: solid 1px $color-white;
  outline: solid 1px $color-light-green;
}

.product-quickshop__sku-select__label {
  font-family: $font--futura-demi;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1;
  font-size: 20px;
}
/* iPad styling */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .custom-kits-mpp-container .product-quickshop .pc-hidden {
    display: none;
  }
  .custom-kits-mpp-container .product-quickshop .mobile-hidden {
    display: block;
  }
  .custom-kits-mpp-container .product-quickshop .product-sku-price {
    width: 100%;
  }
}
